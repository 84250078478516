import { LogLevel } from "angular-auth-oidc-client";

const apiUrl = 'https://api.qa.openflis.us';
const uiUrl = 'https://qa.openflis.us';

export const environment = {
    production: false,
    apiUrl: apiUrl,
    supportEmail: 'happiness@openflis.com',
    authConfig: {
        authority: 'https://dev-6121324.okta.com',
        authWellknownEndpointUrl: 'https://dev-6121324.okta.com/oauth2/ausj0sr758Vy8CAZt5d7/.well-known/openid-configuration',
        postLoginRoute: '/',
        forbiddenRoute: '/warn/forbidden',
        unauthorizedRoute: '/warn/unauthorized',
        // redirectUrl: `${window.location.origin}/search/callback`,
        // postLogoutRedirectUri: `${window.location.origin}/search/warn/logout`,
        redirectUrl: `${uiUrl}/callback`,
        postLogoutRedirectUri: `${uiUrl}/warn/logout`,
        clientId: '0oaj0qwvxkg1iOSb75d7',
        scope: 'openid profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        // triggerRefreshWhenIdTokenExpired: false,
        logLevel: LogLevel.Debug,
        secureRoutes: [apiUrl],
        customParamsAuthRequest: {
            audience: apiUrl,
        },
      }
}